import { gql } from "@apollo/client";

export const OBTENER_DATOS_BOLETO_PV = gql`
  query OBTENER_DATOS_BOLETO_PV($fuid: ID!) {
    obtenerBoletoPV(fuid: $fuid) {
      estatus
      mensaje
    }
  }
`;

//Obtener las ventas del día
export const OBTENER_VENTADIA = gql`
  query OBTENER_VENTADIA {
    OtbenerVentaDia {
      fuid
      nombre_artista
      fecha_evento
      fechaVenta2
    }
  }
`;

//Registrar venta
export const VENDER_BOLETOS = gql`
  mutation VENDER_BOLETO($fuid: ID!) {
    venderBoleto(fuid: $fuid)
  }
`;
