import React from "react";
import TablaVentas from "../components/ventas/TablaVentas";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Page from "../components/Page";
import LectoraQr from "../components/QrPvSales/LectoraQr/LectoraQr";
const VenderBoletos = () => {
  return (
    <>
      <Page Title="Dashboard: Venta | MLC">
        <Container maxWidth="x1">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Módulo de venta
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <LectoraQr esVenta={1} />
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <TablaVentas />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default VenderBoletos;
