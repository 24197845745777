import { Container, Typography, Box, Grid } from "@mui/material";
import React, { useState } from "react";
import Page from "../components/Page";
import LectoraQr from "../components/QrPvSales/LectoraQr/LectoraQr";
const PvQrValidator = () => {
  const [datosBoleto, setDatosBoleto] = useState({
    fuid: "",
    artista: "",
    folio: 0,
    fecha_operacion: "",
  });
  return (
    <Page title="Dashboard: Products | Minimal-UI">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Punto de venta QR
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <LectoraQr setDatosBoleto={setDatosBoleto} esVenta={false} />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            {/* <DataQr datosBoleto={datosBoleto} /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default PvQrValidator;
