// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  // {
  //   title: "dashboard",
  //   path: "/dashboard/app",
  //   icon: getIcon("eva:pie-chart-2-fill"),
  // },
  {
    title: "Punto de Venta",
    path: "/dashboard/pv",
    icon: getIcon("fa6-solid:sack-dollar"),
  },
  {
    title: "Validador",
    path: "/dashboard/puntodeventa",
    icon: getIcon("eva:people-fill"),
  },
  //{
  //   title: "user",
  //   path: "/dashboard/user",
  //   icon: getIcon("eva:people-fill"),
  // },
];

export default sidebarConfig;
