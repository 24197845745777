import { useQuery } from "@apollo/client";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import React from "react";
import { OBTENER_VENTADIA } from "../../../graphql/boleto";
import Scrollbar from "../../Scrollbar";
const TablaVentas = () => {
  //Obtener las ventas del días
  const { data, loading, error } = useQuery(OBTENER_VENTADIA);
  if (loading) return <h1>Cargando</h1>;
  const { OtbenerVentaDia } = data;

  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableBody>
              {OtbenerVentaDia.map((row) => {
                const { fuid, nombre_artista, fecha_evento, fechaVenta2 } = row;
                return (
                  <TableRow hover key={fuid} tabIndex={-1}>
                    <TableCell>{fuid}</TableCell>
                    <TableCell>{nombre_artista}</TableCell>
                    <TableCell>{fecha_evento}</TableCell>
                    <TableCell>{fechaVenta2}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination rowsPerPageOptions={[5, 10, 25]} />
      </Scrollbar>
    </Card>
  );
};

export default TablaVentas;
