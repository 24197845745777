import { Navigate, Outlet } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";

import DashboardApp from "./pages/DashboardApp";
import PvQrValidator from "./pages/PvQrValidator";
import User from "./pages/User";
import NotFound from "./pages/Page404";
import VenderBoletos from "./pages/VenderBoletos";

const routes = (isLogedIn) => [
  {
    path: "/dashboard",
    element: <DashboardLayout />,
    children: [
      // { path: "app", element: <DashboardApp /> },
      // {
      //   path: "user",
      //   element: <User />,
      // },
      { path: "puntodeventa", element: <PvQrValidator /> },
      { path: "pv", element: <VenderBoletos /> },
    ],
  },
  {
    path: "/",
    element: <LogoOnlyLayout />,
    children: [
      { path: "/", element: <Navigate to="/dashboard/puntodeventa" /> },
      { path: "login", element: <Login /> },

      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  { path: "*", element: <Navigate to="/404" replace /> },
];

export default routes;
