import {
  Card,
  CardHeader,
  Dialog,
  Button,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  Modal,
  DialogContent,
} from "@mui/material";
import { useEffect, useState } from "react";
import QrReader from "react-qr-reader";
import QrReader2 from "react-qr-scanner";
import { useLazyQuery, useMutation } from "@apollo/client";
import QrGraphlData from "./qrGraphql";
import FrmDatosBoletos from "../FrmDatosBoletos";
import {
  OBTENER_DATOS_BOLETO_PV,
  VENDER_BOLETOS,
} from "../../../graphql/boleto";
import * as React from "react";
import { Box } from "@mui/system";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LectoraQr = ({ setDatosBoleto, esVenta }) => {
  const [code, setCode] = useState(null);
  const [showDialog, setDiaglog] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [precScan, setPrecScan] = useState({
    mensaje: "",
    estatus: null,
  });
  const [selected, setSelected] = useState("environment");
  const [errorMessage, setErrorMessage] = useState(null);
  const [openModalVenta, setOpenModalVenta] = useState(false);
  const [menssageVenta, setMessageVenta] = useState("");
  const [getDatosBoleto, { data, loading, error }] = useLazyQuery(
    OBTENER_DATOS_BOLETO_PV
  );
  const [venderBoleto] = useMutation(VENDER_BOLETOS);
  //procesar la información del servidor

  async function validateData({ qr = "" }) {
    try {
      setProcessing(true);
      //Petición para solicitar la información del boleto
      //determino si lo que vamos a hacer es una venta
      if (esVenta === 1) {
        try {
          const { data } = await venderBoleto({
            variables: { fuid: qr },
          });
          console.log(data.venderBoleto);
          setMessageVenta(data.venderBoleto);
          setOpenModalVenta(true);
        } catch (error) {
          console.log(error.message);
          setMessageVenta(error.message);
          setOpenModalVenta(true);
        }
      } else {
        try {
          getDatosBoleto({
            variables: {
              fuid: qr,
            },
          });
          setOpenModalVenta(true);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      setMessageVenta(error.message);
      setOpenModalVenta(true);
    }
  }

  useEffect(() => {
    if (data === undefined) {
      console.log("Esperando por datos.... ");
    } else {
      // console.log(data);
      setPrecScan(data.obtenerBoletoPV);
    }
    // data === undefined
    //   ?
    //   :
    //console.log(`Hola data GQL: ${data} `);
    //setDatosBoleto(data);
  }, [data, setDatosBoleto]);

  if (loading) return <h1>cargando...</h1>;

  //Mostrar loading de cargando información

  //Iniciar escaneo
  const handleScan = async (scanData) => {
    console.log(`Cargando datos`, scanData);
    if (scanData && scanData !== "" && !showDialog && !processing) {
      console.log(`loaded >>>`, scanData);
      //setPrecScan(scanData);
      await validateData({ qr: scanData });

      // await fetchData({ qr: scanData });
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  return (
    <>
      <Dialog open={openModalVenta}>
        <DialogContent>
          {" "}
          {
            {
              0: precScan.mensaje,
              1: precScan.mensaje,
              2: precScan.mensaje,
              3: precScan.mensaje,
              null: menssageVenta,
            }[precScan.estatus]
          }
          {/* //{precScan.estatus === (0).menssageVenta} */}
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              setCode(null);
              setErrorMessage(null);
              setOpenModalVenta(false);
              setProcessing(false);
            }}
          >
            Aceptar
          </Button>
        </DialogContent>
      </Dialog>
      {showDialog && (
        <h1>x</h1>
        // <Dialog fullScreen open={showDialog} TransitionComponent={Transition}>
        //   <AppBar sx={{ position: "relative" }}>
        //     <Toolbar>
        //       <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
        //         Información del boleto
        //       </Typography>
        //       <Button
        //         autoFocus
        //         color="inherit"
        //         onClick={() => {
        //           setCode(null);
        //           setErrorMessage(null);
        //           setDiaglog(false);
        //           setProcessing(false);
        //         }}
        //       >
        //         Aceptar
        //       </Button>
        //     </Toolbar>
        //   </AppBar>
        //   {/* <DialogTitle>{"Validador de boletos"}</DialogTitle> */}
        //   <FrmDatosBoletos datosBoleto={precScan} />
        // </Dialog>
      )}
      <Card>
        <CardHeader title="Lector de QR" />
        {!showDialog && !processing && (
          <QrReader
            facingMode={selected}
            delay={500}
            onError={handleError}
            onScan={handleScan}
            // chooseDeviceId={()=>selected}
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </Card>
    </>
  );
};

async function ObtenerDatos(codigo) {}

export default LectoraQr;
