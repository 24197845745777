// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
//componentes
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
//Apollo
import { ApolloProvider } from "@apollo/client";
import client from "./api/apollo";
import { useEffect, useMemo, useState } from "react";
import AuthContext from "./context/Auth.context";
import { decodeToken, getToken, removeToken } from "./utils/token";
import Login from "./pages/Login";
import { useNavigate } from "react-router-dom";
function App() {
  //Validar el inicio de sesion
  const [auth, setAuth] = useState(undefined);
  const navigate = useNavigate();
  //Validar el token
  useEffect(() => {
    const token = getToken();
    if (!token) {
      setAuth(null);
    } else {
      setAuth(decodeToken(token));
    }
  }, []);

  const logout = () => {
    removeToken();
    setAuth(null);
  };

  const setUser = (user) => {
    setAuth(user);
  };

  //Memo
  const authData = useMemo(
    () => ({
      auth,
      logout,
      setUser,
    }),
    [auth]
  );

  const routing = useRoutes(routes(auth));

  return (
    <div>
      <ApolloProvider client={client}>
        <AuthContext.Provider value={authData}>
          <ThemeConfig>
            <ScrollToTop />
            <GlobalStyles />
            <BaseOptionChartStyle />
            {!auth ? <Login /> : routing}
          </ThemeConfig>
        </AuthContext.Provider>
      </ApolloProvider>
    </div>
  );
}

export default App;
