import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
//GRAHPQL
import { LOGIN } from "../../../graphql/usuario";
import { useMutation } from "@apollo/client";
import { decodeToken, setToken } from "../../../utils/token";
import useAuth from "../../../hooks/useAuth";
// ----------------------------------------------------------------------

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ message: "", show: false });

  //Graphql
  const [iniciarSesion] = useMutation(LOGIN);
  const auth = useAuth();
  const { setUser } = auth;

  const LoginSchema = Yup.object().shape({
    usuario: Yup.string().required("El usuario es requerido"),
    password: Yup.string().required("La contraseña es requerida"),
  });

  const formik = useFormik({
    initialValues: {
      usuario: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (datos) => {
      try {
        const { data } = await iniciarSesion({
          variables: {
            input: datos,
          },
        });
        const token = data.iniciarSesion;
        setToken(token);
        setUser(decodeToken(token));
      } catch (error) {
        setErrorMsg({
          message: error.message,
          show: true,
        });
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="usuario"
              label="Usuario"
              {...getFieldProps("usuario")}
              error={Boolean(touched.usuario && errors.usuario)}
              helperText={touched.usuario && errors.usuario}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Contraseña"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            {errorMsg.show && (
              <Alert severity="error">{errorMsg.message}</Alert>
            )}
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Ingresar
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
