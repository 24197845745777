import React from "react";
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";

const FrmDatosBoletos = ({ datosBoleto }) => {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <div>
        <FormControl variant="standard" sx={{ m: 1, mt: 3, width: "25ch" }}>
          <Input
            id="standard-adornment-weight"
            value={datosBoleto.folio}
            endAdornment={<InputAdornment position="end"></InputAdornment>}
            aria-describedby="standard-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
          <FormHelperText id="standard-weight-helper-text">
            Folio de Boleto
          </FormHelperText>
        </FormControl>

        <FormControl variant="standard" sx={{ m: 1, mt: 3, width: "45ch" }}>
          <Input
            id="standard-adornment-weight"
            value={datosBoleto?.fuid}
            endAdornment={<InputAdornment position="end"></InputAdornment>}
            aria-describedby="standard-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
          <FormHelperText id="standard-weight-helper-text">
            Folio UID de Sistema
          </FormHelperText>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, mt: 3, width: "25ch" }}>
          <Input
            id="standard-adornment-weight"
            value={datosBoleto?.fecha_operacion}
            endAdornment={<InputAdornment position="end"></InputAdornment>}
            aria-describedby="standard-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
          <FormHelperText id="standard-weight-helper-text">
            Fecha de Operación
          </FormHelperText>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, mt: 3, width: "45ch" }}>
          <Input
            id="standard-adornment-weight"
            value={datosBoleto?.artista}
            endAdornment={<InputAdornment position="end"></InputAdornment>}
            aria-describedby="standard-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
          <FormHelperText id="standard-weight-helper-text">
            Evento
          </FormHelperText>
        </FormControl>
        {/* <TextField
          id="standard-start-adornment"
          sx={{ m: 1, hwidth: "25ch" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Folio UID:</InputAdornment>
            ),
          }}
          variant="standard"
        /> */}
      </div>
    </Box>
  );
};

export default FrmDatosBoletos;
